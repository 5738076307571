'use client'

import ListingsStandard, { SkeletonCard } from '@component/card/listings/standard'
import SectionLayout from '@component/layout/section'
import SectionSwiper from '@component/layout/section/swiper'
import Select from '@component/select'
import { HOUSE_TYPE_ID } from '@config'
import useFetch from '@hooks/useFetch'
import { hubpageDataLayer } from '@plugin/google/gtm/modules/hubpage'
import { get, isEqual, map, pick, range } from 'lodash-es'
import { useMemo, useState } from 'react'

export default function Content({
  regionId,
  fristData,
  firstParams,
  hubdata
}) {

  const [suburb, setSuburb] = useState(null)

  // find properties url and params
  const [fetchCountUrl, fetchCountParams] = useMemo(() => {
    let url = null
    let params = { typeId: HOUSE_TYPE_ID.village, isCarousel: 1 }

    if (suburb) {
      params.regionId = suburb.value
      url = 'houses/list'
    }

    return [
      url,
      params
    ]
  }, [suburb])
  // 是否显示的首页数据
  const showFirstData = isEqual(firstParams, fetchCountParams)
  const { data, isLoading } = useFetch(showFirstData ? null : { url: fetchCountUrl, params: fetchCountParams })
  // 渲染数据
  const viewData = showFirstData ? fristData : data

  // 显示的region数据
  const regionData = useMemo(() => {
    let res = []
    if (regionId) {
      let defaultIdx = 0
      res = map(regionId, (item, idx) => {
        if (item.defaultSelect) defaultIdx = idx
        return pick(item, ['label', 'value'])
      })
      setSuburb(res[defaultIdx])
    }
    return res
  }, [regionId])

  const title = useMemo(() => {
    return (
      <div className='flex items-center flex-wrap gap-x-6'>
        Find retirement villages in <Select className='text-xl' labelClassName='md:text-3xl' value={suburb} data={regionData} onChange={suburbChangeHandle}></Select>
      </div>
    )
  }, [regionData, suburb])

  function suburbChangeHandle(item, idx) {
    setSuburb(item)
    hubpageDataLayer.findProperty.regionChange(item.label)
  }

  function itemClick() {
    const pageType = get(hubdata, 'data.dataLayer.pageType')
    const verticalType = get(hubdata, 'data.dataLayer.verticalType')
    hubpageDataLayer.findProperty.itemClick(pageType, verticalType)
  }

  return regionId && (
    <SectionLayout title={title} className='section-wrap'>
      {
        isLoading && (
          <SectionSwiper data={range(0, 4)} Card={SkeletonCard}></SectionSwiper>
        )
      }
      {
        viewData && !isLoading && (
          <SectionSwiper
            data={viewData}
            dataKey='id'
            slideClassName='pb-8'
            swiperClassName='-mb-8'
            Card={ListingsStandard}
            cardParams={{ onClick: itemClick, showAgent: false }}
          ></SectionSwiper>
        )
      }
    </SectionLayout>
  )
}
