'use client'

import httpClient from '@apiClient'
import ListingsStandard from '@component/card/listings/standard'
import SectionLayout from '@component/layout/section'
import SectionSwiper from '@component/layout/section/swiper'
import CarouselListing from '@component/skeleton/carouselListing'
import { get, size } from 'lodash-es'
import { useEffect, useState } from 'react'
import { useAsyncFn, useGeolocation } from 'react-use'

export default function HottestListing({
  item
}) {
  const geoState = useGeolocation({ enableHighAccuracy: true, timeout: 10000 })
  const [params, setParams] = useState()
  useEffect(() => {
    if (params) {
      return
    }
    if (geoState.latitude && geoState.longitude && item && item.carouselId) {
      setParams({
        lat: geoState.latitude,
        lng: geoState.longitude,
        carouselId: item.carouselId,
        limit: 40
      })
    } else if (geoState.error) {
      setParams({
        lat: -1,
        lng: -1,
        carouselId: item.carouselId,
        limit: 40,
        timestamp: Date.now()
      })
    }
  }, [params, setParams, geoState.latitude, geoState.longitude, geoState.error, item])

  const [fetchState, fetchData] = useAsyncFn(async (params) => {
    return await httpClient.get('/houses/carousel', params).then(data => {
      if (size(data)) {
        return {
          data,
          title: get(data, 'headers.x-carousel-title'),
        }
      }
      return null
    })
  }, [])

  useEffect(() => {
    if (params) {
      fetchData(params)
    }
  }, [params, fetchData])

  if (fetchState.loading) {
    return <CarouselListing className='section-wrap' />
  }
  if (!fetchState.value) {
    return null
  }

  return (
    <SectionLayout title={`Hottest listings in ${get(fetchState.value, 'title')}`} className='section-wrap'>
      <SectionSwiper
        data={get(fetchState.value, 'data')}
        dataKey='id'
        slideClassName='py-12'
        swiperClassName='-my-12'
        Card={ListingsStandard}
      ></SectionSwiper>
    </SectionLayout>
  )
}