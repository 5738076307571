import DisablePrefetchLink from '@component/disablePrefetchLink'
import Image from 'next/image'
import { useMemo } from 'react'
import getHouseRoute from '@plugin/searchRoute/modules/house'
import getRouter, { URLS, houseList } from '@router'
import { HOUSE_TYPE_ID } from '@config'
import { houseSearchType } from '@routerConfig'
import { getSlug } from '@utils'

export default function TypeCard({
  data,
  params
}) {


  const houseListRoute = useMemo(() => {

    const { typeId, isNewHomes } = params

    if (isNewHomes) {
      if (data.isBuilder) {
        return getRouter(URLS.newhomespageBuilders)
      } else if (data.isLandHome) {
        return getRouter(URLS.newhomespageHouseLand)
      } else {
        return getRouter(URLS.newhomespageApartments)
      }
    }

    const houseRoute = getHouseRoute()
    if (
      [
        HOUSE_TYPE_ID['commercial-property-for-sale'],
        HOUSE_TYPE_ID['commercial-property-for-lease'],
        HOUSE_TYPE_ID['businesses-for-sale'],
      ].includes(typeId)
    ) {
      houseRoute.categories.push(getSlug(data.label, data.filterValue))
    } else {
      houseRoute['property-type'].push(getSlug(data.label, data.filterValue))
    }
    houseRoute.pushNewZealand()

    let searchType
    switch (typeId) {
      case HOUSE_TYPE_ID['commercial-property-for-sale']:
        searchType = houseSearchType.commercialForSale
        break
      case HOUSE_TYPE_ID['commercial-property-for-lease']:
        searchType = houseSearchType.commercialForLease
        break
      case HOUSE_TYPE_ID['businesses-for-sale']:
        searchType = houseSearchType.business
        break
      case HOUSE_TYPE_ID.rural:
        searchType = houseSearchType.rural
        break
      default:
        searchType = houseSearchType.residential
        break
    }

    return houseList(searchType, houseRoute.toSlug())
  }, [data, params])

  return (
    <TypeCardView
      href={houseListRoute}
      imgSrc={data.ossImage}
      label={data.label}
      count={data.houseCount}
    />
  )
}

export function TypeCardView({
  href = '',
  imgSrc = '',
  label = '',
  count = ''
}) {
  return (
    <DisablePrefetchLink href={href} className='flex flex-col items-center py-24 space-y-8 border rounded-sm'>
      {
        imgSrc && (
          <Image src={imgSrc} alt={label} width={64} height={59}></Image>
        )
      }
      <div className='text-font text-lg line-clamp-1 text-center w-full'>{label}</div>
      <div className='text-secondary text-lg'>{count}</div>
    </DisablePrefetchLink>
  )
}

export function SkeletonCard() {
  return (
    <div className='skeleton-wrap h-177'>
      <span className='h-125'></span>
    </div>
  )
}