import { first, get, isArray, isFunction, size } from 'lodash-es'
import { useCallback, useEffect, useMemo } from 'react'
import { useFieldValidator, useFormContext } from '../context'
import FormRow from '../row'
import InputContent from './content'

export default function FormSearchInput({
  label,
  placeholder,
  labelKey = 'label',
  valueKey = 'value',
  data,
  labelRight,
  prop,
  onError,
  className,
  disabled,
  required = false,
  noError = false,
  innerClassName,
  fetcher,
  defaultData
}) {

  const { xs, sm, md, lg, xl, rules, formData, changeField, pushFieldsVerify } = useFormContext()
  const val = get(formData, prop, '')
  const rule = get(rules, prop)
  const [, errors, verify] = useFieldValidator(rule, val, prop)
  const hasError = useMemo(() => size(errors) > 0, [errors])
  const verifyFn = useCallback(() => {
    return verify(rule ? { type: isArray(rule.trigger) ? first(rule.trigger) : rule.trigger } : undefined)
  }, [verify, rule])

  useEffect(() => {
    pushFieldsVerify(prop, verifyFn)
  }, [verifyFn, pushFieldsVerify, prop])
  useEffect(() => {
    isFunction(onError) && onError(errors)
  }, [onError, errors])

  const changeHandle = useCallback((value) => {
    changeField(prop, get(value, valueKey, value))
  }, [changeField, prop, valueKey])
  
  useEffect(() => {
    if (val) {
      verifyFn()
    }
  }, [val, verifyFn])

  return (
    <FormRow label={label} labelRight={labelRight} noError={noError} errors={errors} required={required} className={className}>
      <InputContent
        data={data}
        labelKey={labelKey}
        valueKey={valueKey}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={val}
        onSelect={changeHandle}
        onClear={() => changeHandle()}
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        error={hasError}
        innerClassName={innerClassName}
        fetcher={fetcher}
        defaultData={defaultData}
        // onKeywordChange={changeHandle}
      ></InputContent>
    </FormRow>
  )
}