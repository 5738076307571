'use client'

import SectionLayout from '@component/layout/section'
import SectionSwiper, { SWIPER_BREAKPOINTS } from '@component/layout/section/swiper'
import { LIVING_OPTIONS, LIVING_OPTIONS_LABEL } from '@config'
import IconAssistedLiving from '@icon/home/type-assisted-living.svg?url'
import IconIndependentLiving from '@icon/home/type-independent-living.svg?url'
import IconServicedApartments from '@icon/home/type-serviced-apartments.svg?url'
import IconSpecialistCareHomes from '@icon/home/type-specialist-care-homes.svg?url'
import getHouseRoute from '@plugin/searchRoute/modules/house'
import { houseList } from '@router'
import { houseSearchType } from '@routerConfig'
import { min, size } from 'lodash-es'
import { useMemo } from 'react'
import { TypeCardView } from '../card/type'
import { getSlug } from '@utils'

const ALL_LIVING_OPTIONS = {
  [LIVING_OPTIONS.independentLiving]: { label: LIVING_OPTIONS_LABEL[LIVING_OPTIONS.independentLiving], image: IconIndependentLiving, count: 0, href: '' },
  [LIVING_OPTIONS.servicedApartments]: { label: LIVING_OPTIONS_LABEL[LIVING_OPTIONS.servicedApartments], image: IconServicedApartments, count: 0, href: '' },
  [LIVING_OPTIONS.assistedLiving]: { label: LIVING_OPTIONS_LABEL[LIVING_OPTIONS.assistedLiving], image: IconAssistedLiving, count: 0, href: '' },
  [LIVING_OPTIONS.specialistCareHomes]: { label: LIVING_OPTIONS_LABEL[LIVING_OPTIONS.specialistCareHomes], image: IconSpecialistCareHomes, count: 0, href: '' },
}

export default function LivingOptionsContent({
  title,
  data
}) {

  const viewData = useMemo(() => {
    return [
      getLivingOptions(LIVING_OPTIONS.independentLiving, data),
      getLivingOptions(LIVING_OPTIONS.servicedApartments, data),
      getLivingOptions(LIVING_OPTIONS.assistedLiving, data),
      getLivingOptions(LIVING_OPTIONS.specialistCareHomes, data),
    ]
  }, [data])

  return (
    <SectionLayout title={title} className='section-wrap'>
      <SectionSwiper
        slidesPerView={min([viewData.length, 6])}
        data={viewData}
        dataKey='id'
        Card={Card}
        showNext={false}
        swiperClassName='!pr-30 sm:!pr-0'
        breakpoints={size(viewData) >= 6 ? SWIPER_BREAKPOINTS.typeCard : undefined}
      ></SectionSwiper>
    </SectionLayout>
  )
}

function Card({ data }) {
  return (
    <TypeCardView
      href={data.href}
      imgSrc={data.image}
      label={data.label}
      count={data.count}
    />
  )
}

function getLivingOptions(livingOptions, data) {
  let res = { ...ALL_LIVING_OPTIONS[livingOptions] }
  res.count = data[livingOptions]
  const houseRoute = getHouseRoute()
  houseRoute['living-options'] = [getSlug(res.label, livingOptions)]
  houseRoute.pushNewZealand()
  res.href = houseList(houseSearchType.retirementLiving, houseRoute.toSlug())
  return res
}
