import Autocomplete from '@component/autocomplete'
import { cn } from '@utils'

export default function InputContent({
  disabled = false,
  onSelect,
  onClear,
  placeholder,
  error,
  className,
  innerClassName,
  fetcher,
  labelKey,
  valueKey,
  onKeywordChange,
  xs = false,
  sm = false,
  md = false,
  lg = false,
  xl = false,
  defaultValue,
  defaultData
}) {
  return (
    <Autocomplete
      className={className}
      inputClassName={
        cn(
          innerClassName,
          { 'h-32 sm:h-36 px-12 text-xs': xs },
          { 'h-36 sm:h-40 px-14 text-sm': sm },
          { 'h-40 sm:h-46 px-16 text-base': md },
          { 'h-48 sm:h-52 px-18 text-lg': lg },
          { 'h-52 sm:h-54 px-20 text-xl': xl },
          {
            'border-red': !!error
          }
        )
      }
      fetcher={fetcher}
      onSelect={onSelect}
      onClear={onClear}
      labelKey={labelKey}
      valueKey={valueKey}
      placeholder={placeholder}
      disabled={disabled}
      onKeywordChange={onKeywordChange}
      defaultValue={defaultValue}
      defaultResult={defaultData}
    />
  )
}