'use client'

import Cover from '@component/cover'
import { HOUSE_TYPE_ID } from '@config'
import getBuilderRoute from '@plugin/searchRoute/modules/builder'
import getHouseRoute from '@plugin/searchRoute/modules/house'
import { builderList, houseList } from '@router'
import { houseSearchType } from '@routerConfig'
import { first, get } from 'lodash-es'
import DisablePrefetchLink from '@component/disablePrefetchLink'
import { useMemo } from 'react'

export default function SuburbCard({
  data,
  typeId,
  isNewHomes,
  isApartmentsTerraces,
  isHouseLand,
  isHomeBuilder,
  isVillage,
  price,
  onClick
}) {

  const cover = data.cover || data.coverApp || first(data.images)

  const searchListRoute = useMemo(() => {


    if (isHomeBuilder) {
      const builderRoute = getBuilderRoute()
      builderRoute.setSuburbs(data)
      return builderList(builderRoute.toSlug())
    }

    const houseRoute = getHouseRoute()
    houseRoute.setSuburbs(data)
    if (price) {
      houseRoute.setPrice(get(price, 'value'))
    }

    let searchType
    if (isNewHomes) {
      searchType = houseSearchType.newHomes
      if (isApartmentsTerraces) {
        houseRoute.isLandHome = 0
      } else if (isHouseLand) {
        houseRoute.isLandHome = 1
      }
    } else if (isVillage) {
      searchType = houseSearchType.retirementLiving
    } else {
      switch (parseInt(typeId)) {
        case HOUSE_TYPE_ID['commercial-property-for-sale']:
          searchType = houseSearchType.commercialForSale
          break
        case HOUSE_TYPE_ID['commercial-property-for-lease']:
          searchType = houseSearchType.commercialForLease
          break
        case HOUSE_TYPE_ID['businesses-for-sale']:
          searchType = houseSearchType.business
          break
        case HOUSE_TYPE_ID.rural:
          searchType = houseSearchType.rural
          break
        default:
          searchType = houseSearchType.residential
          break
      }
    }

    return houseList(searchType, houseRoute.toSlug())
  }, [isNewHomes, isApartmentsTerraces, isHouseLand, isHomeBuilder, isVillage, price, typeId, data])

  return (
    <div className='px-12 -mx-12' onClick={onClick}>
      <DisablePrefetchLink href={searchListRoute} className='rounded-sm overflow-hidden shadow-carousel group block'>
        <Cover src={cover} alt={data.label} hoverScale width={210} height={125}></Cover>
        <div className='px-12 py-16 flex justify-between text-sm'>
          <span className='text-font font-medium line-clamp-1'>{data.label}</span>
          <span className='text-secondary'>{data.houseCount || data.builderFranchiseCount || 0}</span>
        </div>
      </DisablePrefetchLink>
    </div>
  )
}

export function SkeletonCard() {
  return (
    <div className='skeleton-wrap h-185'>
      <span className='h-125'></span>
      <div className='flex justify-between mt-10'>
        <span className='w-100 h-10'></span>
        <span className='w-60 h-10'></span>
      </div>
    </div>
  )
}